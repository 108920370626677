/*
 * This file is part of Sift PHP framework.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

(function (Registry) {
  'use strict';

  Registry = Registry || {
    'noop': function () {}
  };

  // Example usage:
  // Define callback with name, the callback name can be passes in data attribute of the widget
  // CallbackRegistry['widget.callbackname'] = function () {};

  // export to window
  window.FormWidgetCallbackRegistry = Registry;

})(window.FormWidgetCallbackRegistry);
